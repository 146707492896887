export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const GETUSER_LOADING = "GETUSER_LOADING";
export const EDIT_DATA = "EDIT_DATA";
export const ONLINE_USERS = "ONLINE_USERS";

export const GETPOST_LOADING = "GETPOST_LOADING";
export const GETPOST_SUCCESS = "GETPOST_SUCCESS";
export const GETPOST_ERROR = "GETPOST_ERROR";

export const POSTED = "POSTED";
export const ACCEPTED = "ACCEPTED";
export const GET_FOLLOWERS = "GET_FOLLOWERS";
export const GET_FOllOWERS_DATA = "GET_FOllOWERS_DATA";
export const GET_FOllOWING_DATA = "GET_FOllOWING_DATA";
export const SHOW_FOLLOWERS = "SHOW_FOLLOWERS";

export const POST_COUNT = "POST_COUNT";
export const GETPROFILE_FOLLOWERS = "GETPROFILE_FOLLOWERS";
export const GETPROFILE_USER = "GETPROFILE_USER";
export const GETPROFILE_POST = "GETPROFILE_POST";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILEPOST_LOADING = "PROFILEPOST_LOADING";

export const SELECTED_CONVO = "SELECTED_CONVO";
export const GET_CONVO = "GET_CONVO";
export const ADD_CONVO = "ADD_CONVO";
export const SHIFT_CONVO = "SHIFT_CONVO";
export const SEND_MSG = "SEND_MSG";
export const SELECTED_IMG = "SELECTED_IMG";
export const DELETE_CONVO = "DELETE_CONVO";
